import Vue from "vue";
import VueRouter from "vue-router";
import Anketa from "../components/Anketa.vue";
import Trudoustrojstvo from "../components/Trudoustrojstvo.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Anketa",
    component: Anketa
  },
  {
    path: "/trudoustrojstvo",
    name: "Trudoustrojstvo",
    component: Trudoustrojstvo
  },
  // {
  //   path: "/:param",
  //   name: "HelloWorld",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../components/Anketa.vue")
  // },
  // {
  //   path: "/trudoustrojstvo",
  //   name: "Trudoustrojstvo",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../components/Trudoustrojstvo.vue")
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
