<template>
  <div>
    <v-row justify="center">
      <v-dialog persistent v-model="dialogQuestion" max-width="600px">
        <v-card>
          <div class="px-5" v-if="!GET_CITIZENSHIP">
            <h3 class=" text-center head-registr pt-4">
              Анкета для офомлення та працевлаштування <br><br>
              Ви громадянин України ?
            </h3>
            <v-card-text>
              <div class="justify-space-around my-4  row">
                <v-btn @click="isUaCitizenShip('UKR')" class="blue--text" color="yellow">Так</v-btn>
                <v-btn @click="isUaCitizenShip('Another')" class="white--text" color="blue-grey">Ні</v-btn>
              </div>
            </v-card-text>
          </div>
          <v-container v-else class="text-center">
            <v-form v-model="isValid" class="px-10">
              <v-row>
                <v-select label="Гражданином какой страны вы явлетесь" v-model="slectedCitizen" :items="citizenships"
                  required :rules="[
                    (v) => !!v || 'Выберите страну',
                  ]">
                </v-select>
              </v-row>
              <v-btn class="my-4" @click="selectCity" color="success" :disabled="!isValid">Дальше
                <v-icon class="pl-4" left>mdi-chevron-right</v-icon>
              </v-btn>
            </v-form>
          </v-container>

          <!-- <v-card-actions>

          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "StartQuestion",
  // props: ["openStartDialog"],

  computed: {
    ...mapGetters([
      "GET_ERROR",
      "GET_CITIZENSHIP"
    ]),

  },

  data () {
    return {
      dialogQuestion: true,
      slectedCitizen: "",
      isValid: true,
      citizenships: ["Беларусь", "Молдова", "Таджикистан", "Азербайджан", "Туркменистан", "Узбекистан", "Афганистан", "Россия (страна террорист)", "Другое"],
      citizenshipsCode: { "Беларусь": "BLR", "Молдова":"MDA", "Таджикистан": "TJK", "Азербайджан": "AZE", "Туркменистан": "TKM", "Узбекистан": "UZB", "Афганистан": "AFG", "Россия (страна террорист)": "RUS", "Другое": "ALL" },
    };
  },
  methods: {
    ...mapActions(["UP_CITIZENSHIP"]),
    isUaCitizenShip (city) {
      console.log(city);
      this.UP_CITIZENSHIP(city);
      if (city == "UA") {
        this.$i18n.locale = "uk";
        this.dialogQuestion = false;
      }
    },
    selectCity () {
      this.UP_CITIZENSHIP(this.citizenshipsCode[this.slectedCitizen]);
      this.$i18n.locale = "ru";
    }
  },
};
</script>


