<template>
  <v-app width="100%">
    <!-- <p>{{GET_AFTE_SEND_FORM }}</p> -->
    <v-main :class="`${GET_PARTNER_DATA.PartnerBackground}  d-flex align-center `">
      <div v-if="!GET_ERROR && !GET_LOAD">
        <v-container>

          <!-- После отправки формы -->
          <div
            v-if="GET_AFTE_SEND_FORM"
            class="text-center white"
          >

            <h3 class="black--text text-center">
              {{ $t('enterPage.success', { "clientName": GET_CLINT_BITRIX_DATA.name }) }}
            </h3>

            <div v-if="!GET_IS_FOR_PARTNERS">
              <br />
              {{ $t('enterPage.goViber') }}
              <v-btn large rounded color="purple" class="white--text text-center go-to-viber"
                :href="`viber://pa?chatURI=teealinfo&context=c${clintBitrixId}`">
                {{ $t('enterPage.goViberBtn') }}
                <v-icon class="iconViber" dark>mdi-comment-arrow-right</v-icon>
              </v-btn>
            </div>

          </div>

          <!-- //После отправки формы -->
          <v-expand-transition>
            <div v-if="
              !GET_CLINT_BITRIX_DATA.redy &&
              !GET_AFTE_SEND_FORM &&
              !GET_HIDE_HEAD
            ">
              <div v-if="haveClintId && (GET_CITIZENSHIP && GET_CITIZENSHIP != 'Another')" class="white">
                <h3 class="black--text text-center">
                  {{ GET_CLINT_BITRIX_DATA.hello }},
                  {{ GET_CLINT_BITRIX_DATA.name }}! {{ $t('enterPage.subTitle') }}
                </h3>
              </div>
            </div>

          </v-expand-transition>

          <div v-if="GET_CLINT_BITRIX_DATA.redy && !resend && !GET_AFTE_SEND_FORM">
            <div v-if="haveClintId" class="text-center white">
              <h3 class="black--text text-center">
                {{ GET_CLINT_BITRIX_DATA.hello }},
                {{ GET_CLINT_BITRIX_DATA.name }}! {{ $t('enterPage.isSended') }}
              </h3>

              <div v-if="!GET_IS_FOR_PARTNERS">
                <h3>
                  {{ $t('enterPage.goViber') }}
                </h3>
                <v-btn large rounded color="purple" class="white--text text-center go-to-viber" :href="
                  `viber://pa?chatURI=teealinfo&context=c${clintBitrixId}`
                "> {{ $t('enterPage.goViberBtn') }}
                  <v-icon class="iconViber" dark>mdi-comment-arrow-right</v-icon>
                </v-btn>

                <div>
                  <v-btn large rounded color="red" class="white--text text-center my-5" @click="resend = true">
                    <span v-html="$t('enterPage.resend')"></span>
                    <v-icon class="iconViber" dark>mdi-account-convert-outline</v-icon>
                </v-btn>
              </div>
              </div>
            </div>
          </div>

        </v-container>
        <!-- анкета -->
        <div class="d-flex  justify-center" v-if="
          ((GET_CITIZENSHIP && GET_CITIZENSHIP != 'Another') && !GET_CLINT_BITRIX_DATA.redy &&
            !isCreateClint &&
            !GET_AFTE_SEND_FORM &&
            !GET_LOAD) ||
          (resend && !GET_AFTE_SEND_FORM)
        ">
          <v-col cols="12" sm="10" md="7">
            <!-- ТУТ ЗАГРУЖАЮТСЯ АНКЕТЫ !!!! -->

            <router-view />

            <!-- ТУТ ЗАГРУЖАЮТСЯ АНКЕТЫ !!!! -->
          </v-col>
        </div>

        <!-- //анкета -->
      </div>




      <StartQuestion v-if="(!GET_CITIZENSHIP || GET_CITIZENSHIP == 'Another')" />



      <div v-else>
      <!-- регистрация -->
      <v-row
        justify="center"
        v-if="!GET_LOAD &&!GET_ERROR"
      >
        <v-dialog
          v-model="isCreateClint"
          persistent
          max-width="600px"
        >

          <v-card>
              <h3 class=" text-center head-registr">{{ $t('enterPage.title') }}
              </h3>

            <v-card-text>
              <v-container>
                <v-form
                  v-model="isValidFerst"
                  v-on:submit.prevent="createClint"
                >
                  <v-row>

                    <v-col cols="10">
                      <!--  :value="newClintPasport" -->
                      <v-text-field
                        v-model="newClintPasport"
                        name="pasport"
                        type="text"
                        :label="$t('enterPage.labelZagran')"
                        :hint="$t('enterPage.exempleZagran')"
                        :rules="[v => (v && v.replace(/[^A-Za-z0-9]/g, '').length >7)|| 'Лише латинські букви, та цифри']"
                        required
                        @input="inputPasport"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="2"
                      class="d-flex align-center"
                    >
                      <v-icon
                        v-if="redyInputPasport"
                        large
                        color="green"
                      >mdi-check-circle-outline</v-icon>

                    </v-col>
                    <v-col
                      cols="12"
                      v-if="isAskName"
                    >
                        <v-text-field v-model="newClintLastName" :label="$t('enterPage.labelSurname')" hint="SHEVCHENKO"
                          required :rules="[(v) => !!v || 'Це обов\'язкове поле']"></v-text-field>

                      <v-text-field
                        v-model="newClintName"
                        :label="$t('enterPage.labelName')"
                        hint="TARAS"
                        required
                        :rules="[v => !!v || 'Це обов\'язкове поле']"
                      ></v-text-field>

                    </v-col>

                  </v-row>
                </v-form>
              </v-container>
              <!-- <small>*indicates required field</small> -->
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" text @click="clear">{{ $t('enterPage.cancel') }}</v-btn>
              <v-spacer></v-spacer>
                <v-btn large class="white--text" color="teal " :disabled="!isValidFerst" @click="createClint">{{
                    $t('enterPage.next')
                }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- //регистрация -->

      <!-- ошибка -->

      <div
        v-if="GET_ERROR"
        class="white"
      >
          <h3 class="black--text text-center">
            {{ $t('enterPage.error', { "error": GET_ERROR }) }}
          </h3>
      </div>
      </div>
      <!-- Лого ТЕЕАЛ -->
      <div
        class="d-flex  justify-center logoTEEAL align-start "
        v-if="!GET_IS_FOR_PARTNERS"
      >
        <v-img class="logo-partner" max-width="40" src="/../logo.png"></v-img>
        <span class="white--text desTEEAL">Designed for TEEAL</span>
      </div>
      <div class="d-flex  justify-center  white--text  logoTEEAL">

      </div>

      <!-- /Лого ТЕЕАЛ -->

      <!-- лого -->
      <div
        class="d-flex  justify-center white logoTEEAL align-start "
        v-if="GET_IS_FOR_PARTNERS"
      >
        <span class="black--text desTEEAL">{{GET_PARTNER_DATA.PartnerBrand}}</span>
      </div>
      <div class="d-flex  justify-center  white--text  logoTEEAL">
      </div>

      <!-- лого -->

      <v-overlay v-if="GET_LOAD">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>

    </v-main>
  </v-app>
</template>

<script>
//import Anketa from "./components/Anketa";
import StartQuestion from "./components/StartQuestion";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import i18n from "@/i18n";
export default {
  name: "App",

  components: {
    StartQuestion
  },
  data() {
    return {
      managerId: null,
      resend: false,
      //newDealId: null,
      haveClintId: false,
      clintBitrixId: null,
      isCreateClint: false,
      newClintPhone: null,
      newClintLastName: null,
      newClintName: null,
      newClintZagran:null,
      newClintPasport:null,
      redyInputPasport:false,
      isAskName: false,
      redyInputPhone: false,
      isValidFerst: true,
      isAfteSendForm: false,
    };
  },

  mounted() {
    const query = this.$route.query;

    // this.$i18n.locale = navigator.language;

    // if (navigator.language == "uk") {
    //   this.$i18n.locale = "uk";
    // } else { this.$i18n.locale = "ru"; }

    if (query.id) {
      //ссылка содержит айди клиента
      //если есть ?id={id}
      if (navigator.language == "uk") {
        this.$i18n.locale = "uk";
      } else { this.$i18n.locale = "ru"; }
      this.UPDATE_CLINT_BITRIX_DATA({ "id": query.id, "lang": this.$i18n.locale }).then(() => {
        if (this.GET_CLINT_BITRIX_DATA.redy) {
          this.UP_CITIZENSHIP("yes");
        }
      });
      let clintIdS = { clinBitrixID: query.id };
      this.SAVE_CLINT_BITRIX_ID(clintIdS);
      this.haveClintId = true;
      this.clintBitrixId = query.id;

    } else if (query.managerid) {
      // ссылка содержит айди менеджера
      this.isCreateClint = true;
      this.managerId = query.managerid;

      this.GET_PARTNER_INFO(query.managerid);
      //если есть ?managerid={managerid}
      //  console.log(query.managerid);
    } else {
      this.CREATE_ERROR("не знайдено ID кліента"); //екшин отправляю ошибку
    }
  },
  methods: {
    ...mapActions([
      "UPDATE_CLINT_BITRIX_DATA", // получает данные оклиенте по айди
      "SEARCH_BITRIX_CLINT", // ищет клиента по номеру телефона
      "CREATE_CLINT", // создает клиента
      "SAVE_CLINT_BITRIX_ID", //записывает айди клиента в стор
      "CREATE_ERROR", // создает ошибку
      "GET_PARTNER_INFO",
      "UP_CITIZENSHIP"
    ]),

    // pasportUserInput(input) {
    //   //const regex2 = new RegExp("[a-z0-9]+$", "gi");

    //   //  let vvod = input.match(/[a-z0-9]/gi);

    //   //  let vvod = input.replace(/[^a-z+$0-9+$]/gi, "");

    //   //input.match(regex2).join("").toUpperCase();

    //   // vvod.join("").toUpperCase();

    //   // console.log(vvod);
    //   // input.replace(/[^A-Za-z0-9]/g, "");

    // //  this.newClintPasport = "1";
    //   //this.newClintPasport = vvod;

    //   //.toUpperCase();
    // },

    inputPasport() {
      //     this.newClintPasport = "1";

      this.newClintPasport = this.newClintPasport
        .toUpperCase()
        .replace(/[^A-Za-z0-9]/g, "");

      //поиск клиента по номеру телефона
      let pasport = this.newClintPasport;

      if (pasport.length > 6) {
        this.isValidFerst = true;
      }

      //console.log(pasport);
      if (pasport.length >= 8) {
        //когда ввел больше 10 символов
        if (
          pasport.length == 8
          // || (phone[0] == 8 && phone.length == 11) ||
          // (phone[0] == 0 && phone.length == 10)
        ) {
          this.redyInputPasport = true;

          this.SEARCH_BITRIX_CLINT(pasport).then((clintData) => {
            if (clintData.status == "emptyContact") {
              this.isAskName = true;
            } else if (clintData.status == "true") {
              if (this.managerId) {
                if (this.managerId !== clintData.managerID) {
                  //this.managerId - менеджер че айди в ссылке
                  //clintData.managerID - менеджер чье айди в битриксе
                  const massageWarn = `Конфликт по клиенту ${clintData.name} ${clintData.LastName} https://teeal.bitrix24.ua/crm/contact/details/${clintData.clintID}/ `;
                  //несовпадают айди созданого клиента
                  axios.get(
                    `https://partners-sender.fenek.fun/senderViber.php?managerId=${this.managerId}&massage=${massageWarn} по вашей анкете обратился клиент другой компании`
                  );
                  axios.get(
                    `https://partners-sender.fenek.fun/senderViber.php?managerId=${clintData.managerID}&massage=${massageWarn} ваш клиент обратился по чужой анкете`
                  );

                  console.log("warning");
                }
              }
              this.redirectToPersonalForm(clintData.clintID);
            }
          });
          // console.log(phone);
        } else if (pasport.length >= 7) {
          // this.isAskName = true;
        }
      }
    },
    createClint() {
      console.log(this.newClintPasport);
      if (this.newClintPasport.replace(/[^A-Za-z0-9]/g, "").length > 6) {
        if (!this.newClintName) {
          // через екшин - ищет клиента и возвращет - есть такой или нет
          this.SEARCH_BITRIX_CLINT(this.newClintPasport).then((clintData) => {
            if (clintData.status == "emptyContact") {
              this.isAskName = true;
            } else if (clintData.status == "true") {
              if (this.managerId) {
                if (this.managerId !== clintData.managerID) {
                  //this.managerId - менеджер че айди в ссылке
                  //clintData.managerID - менеджер чье айди в битриксе
                  const massageWarn = `Конфликт по клиенту ${clintData.name} ${clintData.LastName} https://teeal.bitrix24.ua/crm/contact/details/${clintData.clintID}/ `;
                  //несовпадают айди созданого клиента
                  axios.get(
                    `http://partners-sender.fenek.fun/senderViber.php?managerId=${this.managerId}&massage=${massageWarn} по вашей анкете обратился клиент другой компании`
                  );
                  axios.get(
                    `http://partners-sender.fenek.fun/senderViber.php?managerId=${clintData.managerID}&massage=${massageWarn} ваш клиент обратился по чужой анкете`
                  );

                  console.log("warning");
                }
              }
              //console.log(clintData);
              this.redirectToPersonalForm(clintData.clintID);
            } else {
              this.isCreateClint = false;
              this.CREATE_ERROR(
                "Помилка, з'єднання з сервером, будь ласка перезавантажте сторінку"
              );
            }
          });
        }

        //создаю клиента по менеджером
        if (
          this.newClintPasport &&
          this.newClintName &&
          this.newClintLastName
        ) {
          const datanewClint = JSON.stringify({
            pasport: this.newClintPasport,
            name: this.newClintName,
            lastNameCont: this.newClintLastName,
            zagran:this.newClintPasport.replace(/ /g,""),
            managerID: this.managerId,
            brand: this.GET_PARTNER_DATA.PartnerBrand,
            isPartner: this.GET_IS_FOR_PARTNERS,
          });

          this.CREATE_CLINT(datanewClint).then((newClint) => {
            // this.newDealId = newClint.deal;
            this.redirectToPersonalForm(newClint.clintID, newClint.deal);
          });
        } else {
          this.isAskName = true;
        }
      }
    },
    clear() {
      this.newClintPasport = "";
    },

    redirectToPersonalForm(clintID, dealID) {
      this.UPDATE_CLINT_BITRIX_DATA({ "id": clintID, "lang": this.$i18n.locale });

      let clintIdS = { "clinBitrixID": clintID, "clinDealID": dealID };
      //  "lang": this.$i18n.locale
      this.SAVE_CLINT_BITRIX_ID(clintIdS);
      this.$router.push(`?id=${clintID}`);
      this.isAskName = false;
      this.haveClintId = true;
      this.isCreateClint = false;
      this.clintBitrixId = clintID;
    },
    AfteSendForm(clintID) {
      //  console.log("AfteSendForm");
      this.clintBitrixId = clintID;
      this.haveClintId = false;
      this.isAfteSendForm = true;
    },
  },

  computed: {
    ...mapGetters([
      "GET_CLINT_BITRIX_DATA",
      "GET_ERROR",
      "GET_CLINT_BITRIX_ID",
      "GET_LOAD",
      "GET_AFTE_SEND_FORM",
      "GET_HIDE_HEAD",
      "GET_PARTNER_DATA",
      "GET_IS_FOR_PARTNERS",
      "GET_CITIZENSHIP"
    ]),
  },
};
</script>

<style scoped>
.go-to-viber {
  margin: 40px 0;
}
.go-to-viber a {
  text-decoration: none;
}

.iconViber {
  margin: 10px;
}

.head-registr {
  padding: 15px;
  font-weight: 400;
}

.logoTEEAL {
  margin-top: 25px;
}

.desTEEAL {
  margin: 8px;
}
</style>
