<template>
  <div>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          color="green"
        >
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
          color="green"
        ></v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="3"
          :complete="e1 > 3"
          color="green"
        >
        </v-stepper-step>
      </v-stepper-header>
      <!-- <div class=" sm-6 md-7"> -->
      <v-stepper-items>
        <!-- <v-container class="d-flex
        justify-center
        align-center"> -->

        <!-- карточка 1             -->

        <v-stepper-content
          step="1"
          class="mb-12"
        >
          <v-card min-height="200px ">
            <div class="text-center ">
              <h3>ДЛЯ ВІЗОВОГО ЦЕНТРУ</h3>
            </div>
            <v-card-text>
              <div class=" align-center">
                <v-form v-model="isValid">
                  <!-- дата рождения -->
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="formatDate"
                        label="Дата народження (оберіть рік, місяць та день)*"
                        readonly
                        :rules="[v => !!v || 'Введіть дату народження']"
                        required
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="uk"
                      ref="picker"
                      v-model="clintInputData.birthdate"
                      :max="maxBerthday"
                      min="1950-01-01"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>

                  <!-- //Дата рождения -->

                  <v-select
                    v-model="clintInputData.marital"
                    :rules="[v => !!v || 'Оберіть сімейний стан*']"
                    :items="maritals"
                    item-text="title"
                    item-value="id"
                    menu-props="auto"
                    hide-details
                    label="Сімейний стан*"
                    single-line
                  ></v-select>

                  <!-- <v-text-field
                    class="fildsAnketa"
                    :rules="[v => (v && v.length >9)|| 'Введіть ІПН (10 цифр)']"
                    type="number"
                    v-model="clintInputData.inn"
                    counter="10"
                    label="ІПН (ідентифікаційний код)*"
                    required
                    @focus="HIDE_HEAD"
                  ></v-text-field> -->

                  <v-text-field
                    v-model="clintInputData.FamiliBerthd"
                    :rules="[v => !!v || 'Введіть прізвище при народженні']"
                    counter="50"
                    label="Прізвище при народженні*"
                    hint="Франко"
                    required
                  ></v-text-field>

                </v-form>
              </div>

              <!-- <small>*Пожалуйста заполните все поля</small> -->
            </v-card-text>

            <div class="d-flex justify-center">

              <v-btn
                large
                rounded
                class="white--text"
                color="teal"
                @click="nextStep"
                :disabled="!isValid"
              >
                <!-- :disabled="!isValid"  должно біть-->
                Далі <v-icon
                  class="next-btn-icon"
                  left
                >mdi-chevron-right</v-icon>
              </v-btn>

            </div>

          </v-card>
        </v-stepper-content>

        <!-- /карточка 1             -->

        <!-- карточка  2            -->
        <v-stepper-content
          step="2"
          class="mb-12"
        >
          <v-card min-height="200px">
            <div class=" text-center ">
              <h3>ДЛЯ АНКЕТИ</h3>
            </div>

            <v-card-text>
              <div>
                <v-form v-model="isValid2">
                  <v-text-field
                    label="Місце народження*"
                    v-model="clintInputData.PlaceBirdthd"
                    :rules="[v => !!v || 'Зазначено на 1й сторінці Українського паспорту']"
                    hint="Зазначено на 1й сторінці Українського паспорту"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Індекс за пропискою*"
                    v-model="clintInputData.indexProp"
                    :rules="[v => (v && v.length >1)|| 'Введіть індекс за пропискою']"
                    hint="1234"
                    type="number"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Місце прописки*"
                    v-model="clintInputData.PlaceProp"
                    :rules="[v => !!v || 'Область, місто, вулиця, номер будинку / квартира']"
                    hint="Область, місто, вулиця, номер будинку / квартира"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Імена батьків (мати, тато)*"
                    v-model="clintInputData.ParentsName"
                    :rules="[v => !!v || 'мама, тато']"
                    hint="мати, тато"
                    required
                  ></v-text-field>
                </v-form>
              </div>
            </v-card-text>
            <div class="text-center">

              <div class="d-flex justify-center align-center">
                <v-btn
                  rounded
                  class="btnControlForm"
                  small
                  color="error"
                  @click="e1 --"
                >
                  <v-icon left>mdi-chevron-left</v-icon>Назад
                </v-btn>
                <v-btn
                  large
                  rounded
                  class="white--text"
                  color="teal"
                  @click="nextStep"
                  :disabled="!isValid2"
                >
                  <!-- :disabled="!isValid"  должно біть-->
                  Далі <v-icon
                    class="next-btn-icon"
                    left
                  >mdi-chevron-right</v-icon>
                </v-btn>

              </div>
            </div>
          </v-card>
        </v-stepper-content>
        <!-- /карточка 2            -->

        <!-- карточка  3            -->
        <v-stepper-content
          step="3"
          class="mb-12"
        >
          <v-card min-height="200px">
            <div class=" text-center ">
              <h3>РОЗМІРИ ДЛЯ РОБОТОДАВЦЯ</h3>
            </div>

            <v-card-text>
              <div>
                <v-form v-model="isValid3">
                  <v-text-field
                    label="Розмір взуття*"
                    v-model="clintInputData.shoes"
                    :rules="[v => (v && v.length >1)|| 'Введіть розмір взуття']"
                    hint="39"
                    type="number"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Зріст*"
                    v-model="clintInputData.height"
                    :rules="[v => (v && v.length >2)|| 'Введіть ваш зріст']"
                    hint="165"
                    type="number"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Розмір одягу*"
                    v-model="clintInputData.clothSize"
                    :rules="[v => !!v || 'S,M,XL або 48,35,26']"
                    hint="S,M,XL або 48,35,26"
                    required
                  ></v-text-field>

                  <!-- <v-text-field
                    label="Куди відправити документи і номер відділення Нової Пошти?*"
                    v-model="clintInputData.CityPost"
                    :rules="[v => !!v || 'Введіть куди відправити документи']"
                    hint="Дніпро, відділення 56"
                    required
                  ></v-text-field> -->

                  <v-textarea
                    v-model="clintInputData.koment"
                    filled
                    label="Ім'я та номер близької людини для екстреного зв'язку"
                    rows="2"
                    required
                    :rules="[
                      v => !!v || 'введіть телефон для для екстреного зв\'язку'
                    ]"
                  >

                  </v-textarea>
                </v-form>
              </div>
            </v-card-text>
            <div class="text-center">

              <div class="d-flex justify-center align-center">
                <v-btn
                  rounded
                  class="btnControlForm"
                  small
                  color="error"
                  @click="e1 --"
                >
                  <v-icon left>mdi-chevron-left</v-icon>
                  Назад
                </v-btn>
                <v-btn
                  large
                  rounded
                  class="white--text"
                  color="teal"
                  @click="sendForm"
                  :disabled="!isValid3"
                >
                  <!-- :disabled="!isValid"  должно біть-->
                  Відправити
                </v-btn>

              </div>
            </div>
          </v-card>
        </v-stepper-content>
        <!-- /карточка 3            -->
        <!-- </v-container> -->
      </v-stepper-items>
      <!-- </div> -->
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Trudoustrojstvo",

  computed: {
    ...mapGetters([
      "GET_ERROR",
      "GET_CLINT_BITRIX_ID",
      "GET_CLINT_BITRIX_DATA",
      "GET_CLINT_BITRIX_DEAL_ID"
    ]),

    maxBerthday() {
      let nowDate = new Date();
      nowDate.setFullYear(nowDate.getFullYear() - 18);
      return nowDate.toISOString().substr(0, 10);
    },
    clintData() {
      return this.data;
    },
    formatDate() {
      if (!this.clintInputData.birthdate) return null;
      const [year, month, day] = this.clintInputData.birthdate.split("-");
      return `${day}.${month}.${year}`;
    }
    // attrs() {
    //   return this.date;
    // }
  },

  data() {
    return {
      // clintInputData: {
      //   birthdate: null,
      //   marital: null,
      //   inn: null,
      //   FamiliBerthd: null
      // },

      clintInputData: {},
      valid: true,
      e1: 1, //страница анкеты
      menu: false,
      maritals: [
        // { title: "Сімейний стан", id: "" },
        { title: "Одружений / Одружена", id: "259" },
        { title: "Неодружений / Не заміжня", id: "261" },
        { title: "Видавець / Вдова", id: "265" },
        { title: "Розлучений / Розлучена ", id: "263" }
      ],
      isValid: true,
      isValid2: true,
      isValid3: true
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    }
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    validEmpty(data) {
      if (data) true;
      false;
    },
    nextStep() {
      this.e1++;
      // console.log(this.GET_CLINT_BITRIX_DEAL_ID);
    },
    sendForm() {
      if (this.clintInputData) {
        this.clintInputData["deal"] = this.GET_CLINT_BITRIX_DEAL_ID;
        this.clintInputData["id"] = this.GET_CLINT_BITRIX_ID;
        this.clintInputData["managerID"] = this.GET_CLINT_BITRIX_DATA.managerID;
        this.clintInputData["name"] = this.GET_CLINT_BITRIX_DATA.name;
        this.clintInputData["LastName"] = this.GET_CLINT_BITRIX_DATA.LastName;
        this.clintInputData["phone"] = this.GET_CLINT_BITRIX_DATA.phone;
        // this.clintInputData[""];
        //console.log("work");
        // console.log(this.clintInputData);
        const sendFormData = this.clintInputData;
        const JSONclintInputData = JSON.stringify({
          sendFormData
        });
        this.SAVE_FORM_TO_BITRIX(JSONclintInputData);
      }
    },
    ...mapActions(["SAVE_FORM_TO_BITRIX", "HIDE_HEAD"])
  }
};
</script>

<style lang="scss" scoped>
.btnControlForm {
  margin: 15px;
}

.fildsAnketa {
  // background-color: red;
  margin-top: 17px;
}

.next-btn-icon {
  padding-left: 25px;
}
</style>
