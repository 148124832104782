import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
const pathBackend =
  process.env.NODE_ENV == "development"
    ? "http://1production/anceta-vue/teeal-anketa-vue/public/dat/"
    : "./dat/";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    IsForPartners: true,
    error: false,
    citizenship: null,
    clinBitrixID: null,
    clinDealID: null,
    clintBitrixData: [],
    load: false,
    isAfteSendForm: false,
    isHideHead: false,
    partnerConfig: {
      PartnerBackground: "white",
      PartnerBrand: "",
    },
  },
  mutations: {
    LOAD (state, load) {
      state.load = load;
    },

    AFTE_SEND_FORM (state) {
      state.isAfteSendForm = true;
    },

    WRITE_CLINT_DATA (state, clintBitrixData) {
      state.clintBitrixData = clintBitrixData;
    },

    WRITE_CLINT_BITRIX_ID (state, clintIdS) {
      state.clinBitrixID = clintIdS.clinBitrixID;
      state.clinDealID = clintIdS.clinDealID;
      //console.log(clintID);
      //console.log(state);
    },

    ERROR (state, error) {
      state.error = error;
    },
    SAVE_CITIZENSHIP(state, citizenship) {
      state.citizenship = citizenship;
    },
    CHANGE_HIDE_HEAD (state) {
      state.isHideHead = true;
    },
    SET_PART_COLOR (state, data) {

      state.partnerConfig.PartnerBackground = data.collorPartner;
      state.partnerConfig.PartnerBrand = data.brand;
    },
  },
  actions: {

    CREATE_ERROR ({ commit }, error) {
      commit("ERROR", error);

    },
    UP_CITIZENSHIP({ commit }, citizenship) {
      // console.log("UP_CITIZENSHIP", citizenship);
      commit("SAVE_CITIZENSHIP", citizenship);
    },

    SAVE_CLINT_BITRIX_ID ({ commit }, clintIdS) {
      commit("WRITE_CLINT_BITRIX_ID", clintIdS);
    },
    GET_PARTNER_INFO ({ commit }, partnerId) {

      commit("LOAD", true);
      return (
        axios
          .get(`https://partners-sender.fenek.fun/partnerInfo.php?managerId=${partnerId}`)

          .then((partnerData) => {
            // console.log(partnerData.data);
            if (partnerData.status == 200) {

              commit("SET_PART_COLOR", partnerData.data);
              commit("LOAD", false);


            }

            return partnerData.data;
          })
          // .then(vakans => {
          //   commit("FILTERED_VAKANS_BY_CATEGORY", kategoryes);
          // })
          .catch((error) => {
            commit("ERROR", error);
            console.log("error");
            console.log(error);
            commit("LOAD", false);
            return error;
          })
      );

    },

    UPDATE_CLINT_BITRIX_DATA ({ commit }, idquery) {//получает данные клиента по айди
      const str = JSON.stringify({
        id: idquery.id,
        lang: idquery.lang,
      });

      commit("LOAD", true);

      return axios
        .post(`${pathBackend}getName.php`, str)
        .then((clintBitrixData) => {
            if (clintBitrixData.data.status != "false") {
            //   console.log(clintBitrixData.data);
              commit("WRITE_CLINT_DATA", clintBitrixData.data);
              commit("LOAD", false);
            } else {
              commit("LOAD", false);
              commit("ERROR", "не знайдено ID кліента");
            }

          })

        .catch((error) => {
            commit("ERROR", error);
            console.log("error");
            console.log(error);
            commit("LOAD", false);
            return error;
        });
    },
    SEARCH_BITRIX_CLINT ({ commit }, pasport) {
      //console.log("pas in store " + pasport);
      const str = JSON.stringify({
        pasport: pasport
      });
      commit("LOAD", true);
      return axios
          // .post("https://anketa.teeal.pl/dat/enterZagran.php", str)
          .post(`${pathBackend}enterZagran.php`, str)

          .then((clintSearchBitrix) => {
            commit("WRITE_CLINT_DATA", clintSearchBitrix.data);
            commit("LOAD", false);
            return clintSearchBitrix.data;
          })

        .catch((error) => {
            commit("ERROR", error);
            console.log("error");
            console.log(error);
            commit("LOAD", false);
            return error;
        });
    },
    CREATE_CLINT({ commit }, dataNewClint) {
      commit("LOAD", true);
      return (
        axios
          // .post("https://anketa.teeal.pl/dat/createContZagran.php", dataNewClint)
          .post(`${pathBackend}createContZagran.php`, dataNewClint)

          .then(newClint => {
            //console.log(newClint);

            commit("LOAD", false);

            // commit("WRITE_CLINT_DATA", clintSearchBitrix.data);
            return newClint.data;
          })

          .catch(error => {
            commit("ERROR", error);
            console.log("error");
            console.log(error);
            commit("LOAD", false);
            return error;
          })
      );

    },
    SAVE_FORM_TO_BITRIX ({ commit }, clintInputData) {
      // console.log(clintInputData);

      commit("LOAD", true);
      return axios
        .post(`${pathBackend}UpdateCont.php`, clintInputData)

        .then((newClint) => {
          //console.log("answer", newClint.data);
            if (newClint.data.status == "ok") {
              commit("LOAD", false);
              commit("WRITE_CLINT_BITRIX_ID", newClint.data.cont);
              commit("AFTE_SEND_FORM");
            }

            return newClint.data;
          })

        .catch((error) => {
            commit("ERROR", error);
            console.log("error");
            console.log(error);
            commit("LOAD", false);
            return error;
        });
    },
    HIDE_HEAD ({ commit }) {
      commit("CHANGE_HIDE_HEAD");
    },
  },
  getters: {
    GET_CLINT_BITRIX_DATA (state) {
      return state.clintBitrixData;
    },

    GET_CLINT_BITRIX_ID (state) {
      return state.clinBitrixID;
    },
    GET_CLINT_BITRIX_DEAL_ID (state) {
      return state.clinDealID;
    },
    GET_ERROR (state) {
      return state.error;
    },
    GET_LOAD (state) {
      return state.load;
    },
    GET_AFTE_SEND_FORM (state) {
      return state.isAfteSendForm;
    },
    GET_HIDE_HEAD (state) {
      return state.isHideHead;
    },
    GET_PARTNER_DATA (state) {
      if (state.IsForPartners) {
        return state.partnerConfig;
      } else {
        return {
          PartnerBackground: "teal",
          PartnerBrand: "TEEAL",
        };
      }
    },
    GET_IS_FOR_PARTNERS (state) {
      return state.IsForPartners;
    },
    GET_CITIZENSHIP(state) {
      return state.citizenship;
    },
  },
});
