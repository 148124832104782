<template>
  <div>

    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          color="green"
        >
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="e1 > 2"
          step="2"
          color="green"
        ></v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          step="3"
          :complete="e1 > 3"
          color="green"
        >
        </v-stepper-step>
      </v-stepper-header>
      <!-- <div class=" sm-6 md-7"> -->
      <v-stepper-items>
        <!-- <v-container class="d-flex
        justify-center
        align-center"> -->

        <!-- карточка 1             -->

        <v-stepper-content
          step="1"
          class="mb-12"
        >
          <v-card min-height="200px ">
            <div class="text-center ">
              <h3>{{ $t('ancetaPage.title', { "step": "1/3" }) }}</h3>
            </div>
            <v-card-text>
              <div class=" align-center">
                <v-form v-model="isValid">
                  <!-- дата рождения -->
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="formatDate" :label="$t('ancetaPage.birdthday')" readonly
                        :rules="[(v) => !!v || 'Введіть дату народження']" required v-bind="attrs" v-on="on">
                      </v-text-field>
                    </template>
                    <!-- <v-date-picker :locale="$i18n.locale == 'ua' ? 'uk' : 'ru'" ref="picker" -->
                    <v-date-picker :locale=$i18n.locale ref="picker" v-model="clintInputData.birthdate"
                      :max="maxBerthday" min="1950-01-01" @change="save">
                    </v-date-picker>
                  </v-menu>

                  <!-- //Дата рождения -->

                  <!-- <v-select v-model="clintInputData.marital" :rules="[(v) => !!v || 'Оберіть сімейний стан*']"
                    :items="maritals" item-text="title" item-value="id" menu-props="auto" hide-details
                    label="Сімейний стан*" single-line></v-select> -->

                  <v-text-field v-model="clintInputData.FamiliBerthd"
                    :rules="[(v) => !!v || 'Введіть прізвище при народженні']" counter="50"
                    :label="$t('ancetaPage.birdtFamily')" hint="Франко" required></v-text-field>


                  <v-text-field v-model="clintInputData.polandNumber" name="phone" type="number"
                    :label="$t('ancetaPage.polandPhone')" hint="48..."></v-text-field>

                  <v-text-field v-model="clintInputData.mail" name="mail" type="mail" label="E-MAIL"
                    placeholder="client@gmail.com" :hint="$t('ancetaPage.mailHint')"></v-text-field>
                  <!-- <v-text-field @blur="trimEmail" v-model="clintInputData.mail" name="mail" type="mail" label="E-MAIL"
                    placeholder="client@gmail.com" :hint="$t('ancetaPage.mailHint')"></v-text-field> -->
                </v-form>
              </div>

              <!-- <small>*Пожалуйста заполните все поля</small> -->
            </v-card-text>

            <div class="d-flex justify-center">

              <v-btn
                large
                rounded
                class="white--text"
                color="teal"
                @click="nextStep"
                :disabled="!isValid"
              >
                <!-- :disabled="!isValid"  должно біть-->
                {{ $t('enterPage.next') }}
                <v-icon class="next-btn-icon" left>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <div v-if="!isValid" class="text-center mt-4 text-subtitle-2">
              <div v-if="!clintInputData.birthdate">⚠️ {{ $t('ancetaPage.errorsMasage.enterBirthday') }}</div>
              <div v-if="!clintInputData.FamiliBerthd">⚠️ {{ $t('ancetaPage.errorsMasage.enterFamiliBirthd') }}</div>
              <!-- <div v-if="!clintInputData.mail">⚠️ {{ $t('ancetaPage.errorsMasage.enterMail') }}</div> -->
            </div>

          </v-card>
        </v-stepper-content>

        <!-- /карточка 1             -->

        <!-- карточка  2            -->
        <v-stepper-content
          step="2"
          class="mb-12"
        >
          <v-card min-height="200px">
            <div class=" text-center ">
              <h3>{{ $t('ancetaPage.title', { "step": "2/3" }) }}</h3>
            </div>

            <v-card-text>
              <div>
                <v-form v-model="isValid2">
                  <v-text-field :label="$t('ancetaPage.PlaceBirdthd')" v-model="clintInputData.PlaceBirdthd" :rules="[
                    (v) =>
                      !!v || $t('ancetaPage.hintlaceBirdthd'),
                  ]" :hint="$t('ancetaPage.hintlaceBirdthd')" required></v-text-field>

                  <v-text-field :label="$t('ancetaPage.PlaceProp')" v-model="clintInputData.PlaceProp" :rules="[
                    (v) =>
                      !!v ||
                      $t('ancetaPage.hintPlaceProp'),
                  ]" :hint="$t('ancetaPage.hintPlaceProp')" required></v-text-field>
                  <v-text-field v-if="fildForCitizenShip" :label="$t('ancetaPage.indexProp')"
                    v-model="clintInputData.indexProp" :rules="[
                      (v) =>
                        (v && v.length > 1) || 'Введіть індекс за пропискою',
                    ]" hint="49000" type="number" required></v-text-field>
                  <v-text-field :label="$t('ancetaPage.ParentsName')" v-model="clintInputData.ParentsName"
                    :rules="[(v) => !!v || $t('ancetaPage.placeholderParentsName')]"
                    :placeholder="$t('ancetaPage.placeholderParentsName')" :hint="$t('ancetaPage.hintParentsName')"
                    required
                  ></v-text-field>
                </v-form>
              </div>
            </v-card-text>
            <div class="text-center">

              <div class="d-flex justify-center align-center">
                <v-btn
                  rounded
                  class="btnControlForm"
                  small
                  color="error"
                  @click="e1 --"
                >
                  <v-icon left>mdi-chevron-left</v-icon>Назад
                </v-btn>
                <v-btn
                  large
                  rounded
                  class="white--text"
                  color="teal"
                  @click="nextStep"
                  :disabled="!isValid2"
                >
                  <!-- :disabled="!isValid"  должно біть-->
                  {{ $t('enterPage.next') }}
                  <v-icon class="next-btn-icon" left>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
              </div>
            <div v-if="!isValid2" class="text-center mt-4 text-subtitle-2">
              <div v-if="!clintInputData.PlaceBirdthd">⚠️ {{ $t('ancetaPage.errorsMasage.PlaceBirdthd') }}</div>
              <div v-if="!clintInputData.PlaceProp">⚠️ {{ $t('ancetaPage.errorsMasage.PlaceProp') }}</div>
              <div v-if="!clintInputData.indexProp && fildForCitizenShip">⚠️ {{ $t('ancetaPage.errorsMasage.indexProp')
              }}</div>
              <div v-if="!clintInputData.ParentsName">⚠️ {{ $t('ancetaPage.errorsMasage.ParentsName') }}</div>
            </div>
          </v-card>
        </v-stepper-content>
        <!-- /карточка 2            -->

        <!-- карточка  3            -->
        <v-stepper-content
          step="3"
          class="mb-12"
        >
          <v-card min-height="200px">
            <div class=" text-center ">
              <h3>{{ $t('ancetaPage.title', { "step": "3/3" }) }}</h3>
            </div>

            <v-card-text>
              <div>
                <v-form v-model="isValid3">
                  <v-text-field :label="$t('ancetaPage.height')" v-model="clintInputData.height"
                    :rules="[(v) => (v && v.length > 2) || 'Введіть ваш зріст']" hint="165" type="number" required>
                  </v-text-field>
                  <v-text-field :label="$t('ancetaPage.shoes')" v-model="clintInputData.shoes" :rules="[
                    (v) => (v && v.length > 1) || 'Введіть розмір взуття',
                  ]" hint="39" type="number" required></v-text-field>


                  <v-select :label="$t('ancetaPage.clothSize')" v-model="clintInputData.clothSize" :items="sizes"
                    required :rules="[
                      (v) => !!v || 'Оберіть розмір футболки',
                    ]">
                  </v-select>


                  <div v-if="isHavePesel == 'unknown'" class="my-10">
                    <p class="text-center font-weight-bold text-h6">{{ $t('ancetaPage.havePeselTitle') }}</p>
                    <div class="justify-space-around my-4 row">
                      <v-btn @click="isHavePesel = 'yes'" color="success">{{ $t('ancetaPage.yes') }}</v-btn>
                      <v-btn @click="isHavePesel = 'no'" color="info">{{ $t('ancetaPage.no') }}</v-btn>
                    </div>
                    <p class="text-subtitle-2">{{ $t('ancetaPage.havePeselSubTitle') }}</p>
                  </div>


                  <v-text-field v-if="isHavePesel == 'no' && fildForCitizenShip" class="fildsAnketa my-4" :rules="[
                    (v) => (v && v.length == 10) || 'Введіть ІПН (10 цифр)',
                  ]" type="number" v-model="clintInputData.inn" counter="10" :label="$t('ancetaPage.inn')"
                    :hint="$t('ancetaPage.hintInn')" required @focus="HIDE_HEAD"></v-text-field>

                  <v-text-field v-if="isHavePesel == 'yes'" class="fildsAnketa my-5" :rules="[
                    (v) => (v && v.length == 11) || 'Введіть PESEL (11 цифр)',
                  ]" type="number" v-model="clintInputData.pesel" counter="11" :label="$t('ancetaPage.pesel')"
                    :hint="$t('ancetaPage.hintPesel')" required @focus="HIDE_HEAD"></v-text-field>



                  <v-text-field :label="$t('ancetaPage.CityPost')" v-model="clintInputData.CityPost"
                    placeholder="65175000120000000071118008" :hint="$t('ancetaPage.hintCityPost')">
                  </v-text-field>

                  <v-textarea v-model="clintInputData.koment" filled :label="$t('ancetaPage.koment')" rows="1">
                  </v-textarea>
                </v-form>
              </div>
            </v-card-text>
            <div class="text-center">

              <div class="d-flex justify-center align-center">
                <v-btn
                  rounded
                  class="btnControlForm"
                  small
                  color="error"
                  @click="e1 --"
                >
                  <v-icon left>mdi-chevron-left</v-icon>
                  Назад
                </v-btn>
                <v-btn
                  large
                  rounded
                  class="white--text"
                  color="teal"
                  @click="sendForm"
                  :disabled="!isValid3"
                >
                  <!-- :disabled="!isValid"  должно біть-->
                  {{ $t('ancetaPage.send') }}
                </v-btn>
              </div>
              </div>
            <div v-if="!isValid3" class="text-center mt-4 text-subtitle-2">
              <div v-if="!clintInputData.height">⚠️ {{ $t('ancetaPage.errorsMasage.height') }}</div>
              <div v-if="!clintInputData.shoes">⚠️ {{ $t('ancetaPage.errorsMasage.shoes') }}</div>
              <div v-if="!clintInputData.clothSize">⚠️ {{ $t('ancetaPage.errorsMasage.clothSize') }}</div>
              <div v-if="!clintInputData.inn && !clintInputData.pesel && fildForCitizenShip">⚠️ {{
              $t('ancetaPage.errorsMasage.pesel')
              }}</div>
            </div>
          </v-card>
        </v-stepper-content>
        <!-- /карточка 3            -->
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Anketa",

  computed: {
    ...mapGetters([
      "GET_ERROR",
      "GET_CLINT_BITRIX_ID",
      "GET_CLINT_BITRIX_DATA",
      "GET_CLINT_BITRIX_DEAL_ID",
      "GET_CITIZENSHIP"
    ]),

    fildForCitizenShip () {
      const citizenHideArr = [
        "TJK", "AZE", "TKM", "UZB", "AFG"
      ];

      return citizenHideArr.indexOf(this.GET_CITIZENSHIP) == -1;
    },

    maxBerthday() {
      let nowDate = new Date();
      nowDate.setFullYear(nowDate.getFullYear() - 17);
      return nowDate.toISOString().substr(0, 10);
    },
    clintData() {
      return this.data;
    },
    formatDate() {
      if (!this.clintInputData.birthdate) return null;
      const [year, month, day] = this.clintInputData.birthdate.split("-");
      return `${day}.${month}.${year}`;
    }
    // attrs() {
    //   return this.date;
    // }
  },

  data() {
    return {
      clintInputData: {
        polandNumber: "48"
      },
      valid: true,
      e1: 1, //страница анкеты ВЕРНИ 1 !!!!!!!!!
      menu: false,
      maritals: [
        // { title: "Сімейний стан", id: "" },
        { title: "Одружений / Одружена", id: "259" },
        { title: "Неодружений / Не заміжня", id: "261" },
        { title: "Видавець / Вдова", id: "265" },
        { title: "Розлучений / Розлучена ", id: "263" },
      ],
      sizes: [
        "XS", "S", "M", "L", "XL", "XXL"
      ],
      isHavePesel: "unknown",
      isValid: true,
      isValid2: true,
      isValid3: true,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    validEmpty(data) {
      if (data) true;
      false;
    },
    nextStep() {
      this.e1++;
      window.scrollTo(top);
      // console.log(this.GET_CLINT_BITRIX_DEAL_ID);
    },
    sendForm() {
      if (this.clintInputData) {
        this.clintInputData["deal"] = this.GET_CLINT_BITRIX_DEAL_ID;
        this.clintInputData["id"] = this.GET_CLINT_BITRIX_ID;
        this.clintInputData["managerID"] = this.GET_CLINT_BITRIX_DATA.managerID;
        this.clintInputData["name"] = this.GET_CLINT_BITRIX_DATA.name;
        this.clintInputData["LastName"] = this.GET_CLINT_BITRIX_DATA.LastName;
        this.clintInputData["phone"] = this.GET_CLINT_BITRIX_DATA.phone;
        this.clintInputData["citizenship"] = this.GET_CITIZENSHIP;
        this.clintInputData["zagran"] = this.GET_CLINT_BITRIX_DATA.zagran;
        const sendFormData = this.clintInputData;
        const JSONclintInputData = JSON.stringify({
          sendFormData,
        });

       // console.log(sendFormData);
        this.SAVE_FORM_TO_BITRIX(JSONclintInputData);
      }
    },
    trimEmail () {
      this.clintInputData.mail = this.clintInputData.mail.trim();
    },
    ...mapActions(["SAVE_FORM_TO_BITRIX", "HIDE_HEAD"]),
  },
};
</script>

<style lang="scss" scoped>
.btnControlForm {
  margin: 15px;
}

.fildsAnketa {
  // background-color: red;
  margin-top: 17px;
}

.next-btn-icon {
  padding-left: 25px;
}
</style>
